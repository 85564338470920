<template>
  <div :key="isEnglish" class="liveagent">
    <viiz-header :live-agent="liveAgent" />
    <main v-if="state === 'login'" id="liveagent-login-page">
      <section class="login-section">
        <form id="liveagent-login" autocomplete="off" @submit="submitLoginForm">
          <img class="profile-pic" src="/res/img/user/user.svg" />

          <fieldset class="float-right">
            <label>
              <input
                ref="username"
                :placeholder="getString('input_username')"
                name="username"
                type="text"
                tabindex="1"
                required
                autofocus
              />
            </label>

            <label>
              <input
                ref="password"
                :placeholder="getString('input_password')"
                name="password"
                type="password"
                tabindex="2"
                required
              />
              <button
                id="liveagent-login-password-reset"
                :disabled="loading"
                type="button"
                class="uppercase button--red"
                @click="handleForgotPassword"
              >
                {{ getString('btn_forgot_password') }}
              </button>
            </label>
            <div class="logon">
              <label>
                <button
                  id="liveagent-login-submit"
                  ref="submitButton"
                  :disabled="loading"
                  class="uppercase"
                  tabindex="3"
                >
                  {{ getString('btn_login') }}
                </button>
                <img
                  id="liveagent-login-loading"
                  :class="{ hidden: !loading }"
                  :alt="getString('img_loading_alt')"
                  src="/res/img/loading-blue.gif"
                />
              </label>
              <a class="setLanguage" @click="toggleLanguage"> {{ getString(`btn_lang_${getLang()}`) }} </a>
            </div>
          </fieldset>
        </form>
        <div>
          <p v-if="liveAgent.login.error_message" class="text-danger">{{ getString(liveAgent.login.error_message) }}</p>
        </div>
      </section>
    </main>

    <main v-if="state === 'password_reset'" class="password-reset-form">
      <password-reset :token="token" :on-done="onPasswordResetComplete" />
    </main>
  </div>
</template>

<script>
import ViizHeader from './ViizHeader'
import PasswordReset from './PasswordReset.vue'
import Lang from '../i18n'

export default {
  components: { ViizHeader, PasswordReset },
  props: {
    liveAgent: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      loading: false,
      error_message: '',
      token: null,
      state: null,
      isEnglish: true,
    }
  },
  mounted() {
    const params = new URL(window.location).searchParams
    const action = params.get('action')
    const lang = params.get('lang')

    if (lang) {
      Lang.setLanguage(lang)
      this.$set(this, 'isEnglish', Lang.getLanguage() === 'en')
    }

    switch (action) {
      case 'password_reset':
        this.$set(this, 'state', action)
        this.$set(this, 'token', params.get('token'))
        break
      default:
        this.$set(this, 'state', 'login')
        break
    }
  },
  methods: {
    getLang() {
      return Lang.getLanguage()
    },
    getString(key) {
      return Lang.string(key)
    },
    onPasswordResetComplete() {
      window.history.pushState({}, document.title, '/login')
      this.$set(this, 'state', 'login')
    },
    submitLoginForm: function(event) {
      event.preventDefault(event)
      this.$refs.submitButton.disabled = true
      this.loading = true
      var payload = {
        username: this.$refs.username.value,
        password: this.$refs.password.value,
      }

      var xhr = new XMLHttpRequest()

      xhr.open('post', '/api/sip/validate')
      xhr.setRequestHeader('content-type', 'application/json')
      xhr.onload = () => {
        this.liveAgent.log(7, xhr.status)
        this.liveAgent.log(7, xhr.responseText)

        this.loading = false
        this.$refs.submitButton.disabled = false

        var reason
        var unknownReason = 'error_unexpected_error'

        if (xhr.status === 200) {
          if (xhr.responseText) {
            this.liveAgent.session.validate('login')
          } else {
            this.liveAgent.log(3, 'expected a session id but got :', xhr.responseText)
            this.liveAgent.session.id = ''

            this.liveAgent.login.error_message = unknownReason
          }
        } else {
          this.liveAgent.session.id = ''
          this.$refs.password.value = ''

          switch (xhr.status) {
            case 401:
              reason = Lang.string('error_login_401')
              break
            case 403:
              reason = Lang.string('error_login_403')
              break
            case 503:
              reason = Lang.string('error_login_503')
              break
            default:
              reason = unknownReason
          }

          this.liveAgent.login.error_message = reason
        }
      }

      xhr.send(JSON.stringify(payload))
    },
    handleForgotPassword: function() {
      const params = { name: this.$refs.username.value, lang: Lang.getLanguage() }

      if (!params.name) {
        return (this.liveAgent.login.error_message = 'error_username_required')
      }

      const xhr = new XMLHttpRequest()

      this.$set(this, 'loading', true)

      xhr.open('POST', '/api/request/password/reset')
      xhr.setRequestHeader('content-type', 'application/json')
      xhr.onload = () => {
        this.$set(this, 'loading', false)
        const res = JSON.parse(xhr.response)

        if (xhr.status == 200 && xhr.status < 300) {
          this.liveAgent.login.error_message = 'message_password_reset_email'
        }

        if (res.errors) {
          this.liveAgent.login.error_message = 'error_password_reset_request_failed'
        }
      }

      xhr.send(JSON.stringify(params))
    },
    toggleLanguage: function() {
      let lang = Lang.getLanguage() === 'en' ? 'fr' : 'en'
      Lang.setLanguage(lang)
      this.$set(this, 'isEnglish', lang === 'en')
    },
  },
}
</script>
<style scoped>
.uppercase {
  text-transform: uppercase;
}
.password-reset-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-section {
  display: flex;
  align-items: center;
  width: 450px;
  flex-direction: column;
}
</style>
