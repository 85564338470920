let self = {}
let lang = 'en'

const texts = {
  en: {
    header_password_reset: 'Password Reset',
    header_request_complete: 'Request Completed',
    input_username: 'Username...',
    input_password: 'Password...',
    input_new_password: 'New Password',
    input_re_enter_password: 'Re-Enter New Password',
    btn_forgot_password: 'I forget',
    btn_login: 'Log on',
    btn_submit: 'Submit',
    btn_continue_to_login: 'Continue to Login',
    btn_lang_fr: 'English',
    btn_lang_en: 'Français',
    img_loading_alt: 'Validating credentials... please wait...',
    message_password_reset_email:
      'You will receive an email shortly with steps to reset your password. If you do not have an email attached to your account, please contact your supervisor.',
    message_processing_request: 'Processing your request',
    message_password_reset_complete: 'You can now login with your new password',
    error_unexpected_error: 'Unexpected failure. Please notify your supervisor.',
    error_login_401: '401 Invalid Credentials',
    error_login_403: '403 Not Authorized',
    error_login_503: '503 SIP Service Unavailable. Try again or contact your supervisor if the problem persists.',
    error_username_required: 'Please fill in the required field, username',
    error_password_reset_request_failed:
      'Failed to send password reset request, please try again or contact a Supervisor if the issue persists.',
    error_password_reset_token_corrupt_expired:
      'Invalid or expired Token, please try submitting a new password reset request.',
    error_password_not_meet_criteria: 'Password does not meet the criteria',
    error_agent_password_length: 'Password must be at least 8 characters in length',
    error_agent_password_lowercase_uppercase:
      'Password must contain a combination of lowercase and uppercase characters',
    error_agent_password_not_match: 'Passwords do not match',
  },
  fr: {
    header_password_reset: 'Réinitialisation du mot de passe',
    header_request_complete: 'Demande terminée',
    input_username: "Nom d'utilisateur...",
    input_password: 'Mot de passe...',
    input_new_password: 'Nouveau mot de passe',
    input_re_enter_password: 'Ré-entrez le nouveau mot de passe',
    btn_forgot_password: "J'ai Oublié",
    btn_login: 'Ouvrir la session',
    btn_submit: 'Soumettre',
    btn_continue_to_login: 'Continuer à se connecter',
    btn_lang_fr: 'English',
    btn_lang_en: 'Français',
    img_loading_alt: "Validation des informations d'identification ... veuillez patienter ...",
    message_password_reset_email:
      "Vous recevrez sous peu un courriel avec les étapes pour réinitialiser votre mot de passe. Si vous n'avez pas de courriel associé à votre compte, veuillez contacter votre superviseur.",
    message_processing_request: 'Traitement de votre demande',
    message_password_reset_complete: 'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe',
    error_unexpected_error: 'Échec inattendu. Veuillez informer votre superviseur.',
    error_login_401: '401 Information invalide',
    error_login_403: '403 Non autorisé',
    error_login_503: '503 Service SIP indisponible. Réessayez ou contactez votre superviseur si le problème persiste.',
    error_username_required: "Veuillez remplir le champ obligatoire, nom d'utilisateur",
    error_password_reset_request_failed:
      "Échec de l'envoi de la demande de réinitialisation du mot de passe, veuillez réessayer ou contacter un superviseur si le problème persiste.",
    error_password_reset_token_corrupt_expired:
      'Jeton non valide ou expiré, veuillez essayer de soumettre une nouvelle demande de réinitialisation de mot de passe.',
    error_password_not_meet_criteria: 'Le mot de passe ne répond pas aux critères',
    error_agent_password_length: 'Le mot de passe doit comporter au moins 8 caractères',
    error_agent_password_lowercase_uppercase:
      'Le mot de passe doit contenir une combinaison de caractères minuscules et majuscules',
    error_agent_password_not_match: 'Les mots de passe ne correspondent pas',
  },
}

self.setLanguage = function(code) {
  if (Object.keys(texts).includes(code)) {
    lang = code
  }
}

self.getLanguage = function() {
  return lang + ''
}

self.string = function(key, params = {}) {
  let str = texts[lang][key] || key
  const keys = Object.keys(params)

  keys.forEach(k => {
    const find = new RegExp(`{{${k}}}`, 'g')
    str = str.replace(find, params[k])
  })

  return str
}

export default self
