<template>
  <section style="width: 500px;">
    <h5 class="heading">{{ getString('header_password_reset') }}</h5>

    <div class="form">
      <div v-show="!completed" class="user-profile">
        <img class="profile-pic" src="/res/img/user/user.svg" />
        <p style="text-align: center;">
          {{ data.name }} <br />
          {{ data.email }}
        </p>
      </div>

      <div v-if="!data.id">
        <p class="text-danger">{{ getString('error_password_reset_token_corrupt_expired') }}</p>
      </div>

      <div v-if="data.id" v-show="!loading && !completed" class="fields">
        <label>
          <input
            ref="password"
            v-model="password"
            :placeholder="getString('input_new_password')"
            name="password"
            type="password"
            tabindex="1"
            required
            autofocus
          />
        </label>

        <label>
          <input
            ref="renter_password"
            v-model="renter_password"
            :placeholder="getString('input_re_enter_password')"
            name="renter_password"
            type="password"
            tabindex="2"
            required
          />
        </label>

        <label>
          <button :disabled="loading" tabindex="3" @click="submit">{{ getString('btn_submit') }}</button>
        </label>
      </div>

      <div v-show="loading" class="loading-wrapper">
        <img :class="['loading', { hidden: !loading }]" src="/res/img/loading-blue.gif" alt="please wait..." />
        <p>{{ getString('message_processing_request') }}</p>
      </div>
    </div>

    <div v-show="completed" class="completed">
      <h5>{{ getString('header_request_complete') }}</h5>

      <p>{{ getString('message_password_reset_complete') }}</p>

      <label>
        <button @click="exit">{{ getString('btn_continue_to_login') }}</button>
      </label>
    </div>

    <div v-if="error" class="errors">
      <p class="text-danger">{{ getString(error) }}</p>
    </div>
  </section>
</template>

<script>
import Lang from '../i18n'

export default {
  name: 'PasswordReset',
  props: {
    token: {
      type: String,
      default: '',
    },
    onDone: {
      type: Function,
      required: true,
    },
  },
  data: () => {
    return {
      password: '',
      renter_password: '',
      loading: false,
      error: null,
      completed: false,
      data: {},
    }
  },
  mounted() {
    const data = this.parsePayload(this.token)
    this.$set(this, 'data', data)
  },
  methods: {
    getString(key) {
      return Lang.string(key)
    },
    parsePayload(token) {
      const hash = (token || '').split('.')[1] || ''

      try {
        return JSON.parse(window.atob(hash) || {})
      } catch (e) {
        return {}
      }
    },
    isInvalidPassword(pw, pw2) {
      if (!/^.{8,}$/.test(pw)) {
        return 'error_agent_password_length'
      }
      if (!/^[a-zA-Z0-9]*$/.test(pw)) {
        return 'error_agent_password_alphanumeric_spaces'
      }
      if (!/^(?=.*?[A-Z])(?=(.*[a-z]){1,})/.test(pw)) {
        return 'error_agent_password_lowercase_uppercase'
      }

      if (pw !== pw2) {
        return 'error_agent_password_not_match'
      }

      return false
    },
    exit() {
      this.onDone()
    },
    submit: function() {
      this.$set(this, 'loading', true)
      // reset error on re-submission
      this.$set(this, 'error', null)

      const isInvalid = this.isInvalidPassword(this.password, this.renter_password)
      // validate passwords & match
      if (isInvalid) {
        this.$set(this, 'loading', false)
        return this.$set(this, 'error', isInvalid)
      }

      const params = {
        password: this.password,
        token: this.token,
      }

      var xhr = new XMLHttpRequest()

      xhr.open('POST', `/api/password/reset/`)
      xhr.setRequestHeader('content-type', 'application/json')
      xhr.onload = () => {
        this.$set(this, 'loading', false)
        const res = JSON.parse(xhr.response)

        if (xhr.status === 200 && xhr.status < 300) {
          return this.$set(this, 'completed', true)
        }

        if (res.errors) {
          const err = res.errors[0]

          if (err.code === 'error_password_reset_missing_parameter') {
            return this.$set(this, 'error', 'error_unexpected_error')
          }

          return this.$set(this, 'error', `${err.code}`)
        }
      }

      xhr.send(JSON.stringify(params))
    },
  },
}
</script>
<style scoped>
.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loading {
  margin: 1rem;
}
.heading {
  margin: 1rem;
  text-align: center;
}
.form {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.fields {
  justify-content: center;
}
.user-profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.completed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.errors {
  text-align: center;
}
</style>
